@media (width <= 80em) {
  .title-container--home {
    padding: 5%;
  }

  .box--container {
    width: 90%;
  }

  .box--container-approach {
    grid-template-columns: 1fr;
    display: grid;
  }

  .action--box:link, .action--box:visited, .action--box:hover, .action--box:active {
    justify-self: center;
    width: 30%;
    padding: 3.6rem;
  }

  .ph-globe-hemisphere-east, .ph-music-notes {
    font-size: 2.1rem;
  }

  .quote {
    height: 10rem;
  }
}

@media (width <= 68em) {
  .submit-btn {
    width: 100%;
  }

  .ph-globe-hemisphere-east, .ph-music-notes {
    display: none;
  }
}

@media (width <= 37.5em) {
  .title-container--home {
    gap: 2.1rem;
    width: 85%;
  }

  .title-container--home h1 {
    font-size: 2.4rem;
  }

  .dedication {
    font-size: 1.8rem;
  }

  .explained--text {
    width: 95%;
  }

  .box--container {
    width: 85%;
  }

  .box--container-approach {
    grid-template-columns: 1fr;
    padding-top: 7rem;
    display: grid;
  }

  .action--box:link, .action--box:visited, .action--box:hover, .action--box:active {
    justify-self: center;
    width: 70%;
    padding: 3.6rem;
  }

  .home--text-box, .pThree, .pFive {
    width: 100%;
  }

  .quote {
    font-size: 1.6rem;
  }
}

@media (width <= 22.5em) {
  .title-container--home {
    width: 90%;
  }
}
/*# sourceMappingURL=home.6d4adcdf.css.map */
